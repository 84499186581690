import React from 'react'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import Title from 'components/title'
import Paragraph from 'components/paragraph'

const NotFoundPage = () => (
  <Layout path="/404">
    <Wrap>
      <Title is="h1">Goodness me</Title>
      <Paragraph>This page has fallen into a black hole.</Paragraph>
    </Wrap>
  </Layout>
)

export default NotFoundPage
